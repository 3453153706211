<template>
  <Loader type="agencies" v-if="loading"></Loader>
  <v-data-iterator
    v-else-if="!loading"
    :custom-sort="customSort"
    :search="search"
    :items="agencies"
    :sort-desc="sortDesc"
    hide-default-footer>
    <template v-slot:header>
      <v-toolbar dark color="primary">
        <v-text-field v-model="search" hide-details prepend-inner-icon="mdi-magnify"
                      :label="$t('search')"></v-text-field>
        <v-spacer></v-spacer>
        <v-select v-model="sortBy" flat hide-details :items="keys" prepend-inner-icon="mdi-sort-ascending"
                  :label="$t('all-agencies.sort-by')">
        </v-select>
        <v-btn-toggle tile dense group v-model="sortDesc" mandatory>
          <v-btn small tile color="primary" :value="false">
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn small tile color="primary" :value="true">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-toolbar>
    </template>

    <v-flex>
      <v-container>
        <v-alert v-if="filterAgencies.length<1" dense outlined type="error">
          {{$t('all-agencies.no-search-results-1')}} {{ search }} {{$t('all-agencies.no-search-results-2')}}
        </v-alert>
      </v-container>
      <v-layout wrap>
        <v-col v-for="agency in filterAgencies" :key="agency.agencyId">
          <v-card elevation="1" class="mx-auto my-1" max-width="300">
            <router-link :to="{ path: '/agency/' + agency.agencyId}">
              <v-img :src="require(`@/assets/agencylogos/${agency.logoPath}`)" max-width="100%">
                <v-badge dot class="custom-class" bordered inline :color="agency.active?'primary':'error'"></v-badge>
              </v-img>
            </router-link>

            <v-card-title style="text-transform: uppercase">
              <router-link :to="{ path: '/agency/' + agency.agencyId}" style="text-decoration: none;font-size: 14px">
                {{agency.agencyName}}
              </router-link>
            </v-card-title>

            <v-card-text>
              <v-row class="ma-auto">
                <div class="grey--text" style="font-size: 14px">
                  {{agency.prefix}}
                </div>
                <v-spacer></v-spacer>
                <div class="grey--text" style="font-size: 14px">{{agency.city}}</div>
              </v-row>
            </v-card-text>

            <v-divider dark></v-divider>

            <v-card-text>
              <div class="my-4 subtitle-1">
                <v-icon left>mdi-email-outline</v-icon>
                <a :href="`mailto:${agency.email}`" style="text-decoration: none"
                   class="grey--text">{{agency.email}}</a>
              </div>

              <div class="my-4 subtitle-1">
                <v-icon left>mdi-web</v-icon>
                <a :href=agency.website target="_blank" style="text-decoration: none" class="grey--text">{{agency.website}}</a>
              </div>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-text>
              <v-list flat>
                <v-subheader>{{$t('all-agencies.statistics')}}</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-counter</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="grey--text">
                        <v-list-item-title>{{$t('all-agencies.order-count')}}: <span
                          class="text--primary">{{countAgencyOrders(agency.agencyId)}}</span></v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-book-open-page-variant-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="grey--text">
                        <v-list-item-title>{{$t('all-agencies.total-pages')}}: <span
                          class="text--primary">{{countTotalPages(agency.agencyId)}}</span></v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-cash-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="grey--text">
                        <v-list-item-title>{{$t('all-agencies.total-cost')}}: <span
                          class="text--primary">{{$t('currencies.uah')}} {{countTotalCost(agency.agencyId).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}}</span>
                        </v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-sack-percent</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="grey--text">
                        <v-list-item-title>{{$t('all-agencies.percentage')}}: <span
                          class="text--primary">{{countPercentage(agency.agencyId)}}%</span></v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>mdi-cash</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <div class="grey--text">
                        <v-list-item-title>{{$t('all-agencies.avg-order-cost')}}: <span
                          class="text--primary">{{$t('currencies.uah')}} {{countAvgOrderCost(agency.agencyId)}}</span>
                        </v-list-item-title>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-col>
      </v-layout>
    </v-flex>
  </v-data-iterator>
</template>

<script>
  import Loader from "../../components/Loaders/Loader";
  import {useAgencies} from "../../stores/agenciesStore";
  import {useOrders} from "../../stores/ordersStore";
  import {mapState} from "pinia";

  const agencyStore = useAgencies()
  const ordersStore = useOrders()

  export default {
    components: {Loader},
    name: "All Agencies",
    data() {
      return {
        search: '',
        sortDesc: true,
        sortBy: this.$t('sort-keys.default'),
        keys: [
          this.$t('sort-keys.default'),
          this.$t('sort-keys.agency-name'),
          this.$t('sort-keys.city'),
          this.$t('sort-keys.created-date'),
          this.$t('sort-keys.modified-date')
        ],
        filteredAgencies: [],
        totalSum: 0
      }
    },
    computed: {
      ...mapState(useAgencies, ['agencies']),
      ...mapState(useOrders, ['orders', 'loading']),
      filterAgencies() {
        return this.filteredAgencies = this.agencies.filter(i => i.agencyName.toLowerCase().includes(this.search.toLowerCase()));
      },
      customSort() {
        let param = this.sortBy;
        let desc = this.sortDesc;
        let sortArray = [1, -1];
        if (!desc) {
          sortArray = [-1, 1]
        }
        if (param === this.$t('sort-keys.default')) {
          this.agencies.sort((a, b) => (a.active > b.active) ? sortArray[1] : sortArray[0])
        }
        if (param === this.$t('sort-keys.agency-name')) {
          this.agencies.sort((a, b) => (a.agencyName > b.agencyName) ? sortArray[0] : sortArray[1])
        }
        if (param === this.$t('sort-keys.city')) {
          this.agencies.sort((a, b) => (a.city > b.city) ? sortArray[0] : sortArray[1])
        }
        if (param === this.$t('sort-keys.created-date')) {
          this.agencies.sort((a, b) => (a.createdTime > b.createdTime) ? sortArray[0] : sortArray[1])
        }
        if (param === this.$t('sort-keys.modified-date')) {
          this.agencies.sort((a, b) => (a.modifiedTime > b.modifiedTime) ? sortArray[0] : sortArray[1])
        }
      },
    },
    methods: {
      updateNeededCheck() {
        if (!this.orders.length || !this.agencies.length) {
          ordersStore.populateOrders()
          this.getTotalSum()
          agencyStore.getAgencyList()
        }
      },
      async getTotalSum() {
        this.totalSum = this.orders.map((order) => order.totalCost).reduce(function (previous, current) {
          return previous + current;
        }, 0);
      },
      countAgencyOrders(agencyId) {
        return this.orders.filter(e => e.agency.agencyId === agencyId).length;
      },
      countTotalPages(agencyId) {
        return this.orders.filter(e => e.agency.agencyId === agencyId)
          .map((agency) => agency.pageCount)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0).toFixed(2);
      },
      countTotalCost(agencyId) {
        return this.orders.filter(e => e.agency.agencyId === agencyId)
          .map((agency) => agency.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
      },
      countPercentage(agencyId) {
        let totalSum = this.orders.map((agency) => agency.totalCost).reduce(function (previous, current) {
          return previous + current;
        }, 0);
        let totalAgencyCost = this.orders.filter(e => e.agency.agencyId === agencyId)
          .map((order) => order.totalCost)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
        return (totalAgencyCost / totalSum * 100).toFixed(2);
      },
      countAvgOrderCost(agencyId) {
        return (this.countTotalCost(agencyId) / this.countAgencyOrders(agencyId)).toFixed(0).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
      },
      // countRating(agencyId) {
      //   //Part 1: Calculate rating by Total Cost
      //   let totalSum = this.orders.map((agency) => agency.totalCost).reduce(function (previous, current) {
      //     return previous + current;
      //   }, 0);
      //   let totalAgencyCost = this.orders.filter(e => e.agency.agencyId === agencyId)
      //     .map((agency) => agency.totalCost)
      //     .reduce(function (previous, current) {
      //       return previous + current;
      //     }, 0);
      //   let ratingByTotalCost = totalAgencyCost / totalSum * 10;
      //   // console.log('ratingByTotalCost:' + ratingByTotalCost)
      //
      //   //Part 2: Calculate rating by Total number of orders
      //   let ratingByOrderNumber = (this.countAgencyOrders(agencyId) / this.orders.length) * 10;
      //   // console.log('ratingByOrderNumber:' + ratingByOrderNumber)
      //
      //   //Part 3: Calculate total rating (using 2 parameters)
      //   return Math.round(ratingByOrderNumber + ratingByTotalCost / 2) / 2;
      // },
    },
    created() {
      this.updateNeededCheck();
    },
  }
</script>
